import React ,{useState} from 'react';
import './Home.css';

function Home() {
  const [selectedImage, setSelectedImage] = useState(null);
  const openPopup = (image) => {
    setSelectedImage(image);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };
  
 
  return (
    <div className="home-container">
      <h1>Rajam Florals</h1>
      <p><em>Blossoms Beyond Beauty</em></p>
      <div>
      <p >Rajam Florals, we specialize in creating stunning floral arrangements and decorations for all occasions.<br></br>
        Whether you're planning a grand wedding, a cozy birthday celebration, or any other special event, 
        we’re here to bring your vision to life with our expertly crafted flowers.
        
    </p>
    
<p class="large-text"><strong><em>Our Services Include</em></strong></p>
<div >
<ul className="bullet-points">
  <li className="service" ><p class="extra=large-text"><em>Weddings</em></p>
  From intimate ceremonies to lavish receptions, we provide breathtaking floral decor that enhances every moment.
  <div className="image-gallery">
        <div className="image-item">
          <img src="/images/flower1.jpeg" alt="Flower 1" onClick={() => openPopup("/images/flower1.jpeg")}/>
        </div>
        </div>
        </li>
        <li className="service" ><p class="extra=large-text"><em>Birthdays</em></p>
          Brighten your celebration with vibrant bouquets and festive floral designs.
         
  </li>

        <li className="service" ><p class="extra=large-text"><em>Special Occasions</em></p>
  
      Be it anniversaries, corporate events, or themed parties, we deliver floral perfection tailored to your needs.

  </li>
 
</ul>
</div>
      </div>

      <div className="image-gallery">
        <div className="image-item">
          <img src="/images/flower1.jpeg" alt="Flower 1" onClick={() => openPopup("/images/flower1.jpeg")} />
    
        </div>
        <div className="image-item">
          <img src="/images/flower2.jpeg" alt="Flower 2" onClick={() => openPopup("/images/flower2.jpeg")}/>
       
        </div>
        <div className="image-item">
          <img src="/images/flower3.jpeg" alt="Flower 3" onClick={() => openPopup("/images/flower3.jpeg")}/>
       
        </div>
      
        <div className="image-item">
          <img src="/images/flower4.jpeg" alt="Flower 4" onClick={() => openPopup("/images/flower4.jpeg")}/>
        
          </div>
          <div className="image-item">
          <img src="/images/dom-flower3.JPG" alt="Flower 4" onClick={() => openPopup("/images/dom-flower3.JP")}/>
        
          </div>
          <div className="image-item">
          <img src="/images/dom-flower8.JPG" alt="Flower 4" onClick={() => openPopup("/images/dom-flower8.JPG")}/>
          </div>
          <div className="image-item">
          <img src="/images/dom-flower1.JPG" alt="Flower 4" onClick={() => openPopup("/images/dom-flower1.JPG")}/>
        </div>
      </div>
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
    </div>
  );


}


export default Home;
