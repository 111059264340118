import React from 'react';

function Contact() {
    return (
        <div>
          <h1>Contact Us</h1>
          <p>Contact us Mobile Number : 630 779 1703 </p>
        </div>
      );
}

export default Contact;
