import React,{useState} from 'react';



function About() {
  const [selectedImage, setSelectedImage] = useState(null);
  const openPopup = (image) => {
    setSelectedImage(image);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };
  const images1 = [
    "/images/IMG-20250106-WA0001.jpg",
    "/images/IMG-20250106-WA0002.jpg",
    "/images/IMG-20250106-WA0003.jpg",
    "/images/IMG-20250106-WA0004.jpg",
    "/images/IMG-20250106-WA0005.jpg",
    "/images/IMG-20250106-WA0006.jpg",
    "/images/IMG-20250106-WA0007.jpg",
  ];

  const images2 = [
    "/images/IMG-20250106-WA0008.jpg",
    "/images/IMG-20250106-WA0009.jpg",
    "/images/IMG-20250106-WA0010.jpg",
    "/images/IMG-20250106-WA0011.jpg",
    "/images/IMG-20250106-WA0012.jpg",
    "/images/IMG-20250106-WA0013.jpg",
    "/images/IMG-20250106-WA0014.jpg",
  ];

  const images3 = [
    "/images/IMG-20250106-WA0015.jpg",
    "/images/IMG-20250106-WA0016.jpg",
    "/images/IMG-20250106-WA0017.jpg",
    "/images/IMG-20250106-WA0018.jpg",
    "/images/IMG-20250106-WA0019.jpg",
    "/images/IMG-20250106-WA0020.jpg",
    "/images/IMG-20250106-WA0021.jpg",
  ];

  const images4 = [
    "/images/IMG-20250106-WA0022.jpg",
    "/images/IMG-20250106-WA0023.jpg",
    "/images/IMG-20250106-WA0024.jpg",
    "/images/IMG-20250106-WA0025.jpg",
    "/images/IMG-20250106-WA0026.jpg",
    "/images/IMG-20250106-WA0027.jpg",
    "/images/IMG-20250106-WA0028.jpg",
  ];
  const images5 = [
    "/images/IMG-20250106-WA0029.jpg",
    "/images/IMG-20250106-WA0030.jpg",
    "/images/IMG-20250106-WA0031.jpg",
    "/images/IMG-20250106-WA0032.jpg",
    "/images/IMG-20250106-WA0033.jpg",
    "/images/IMG-20250106-WA0034.jpg",
    "/images/IMG-20250106-WA0035.jpg",
  ];

  const images6 = [
    "/images/IMG-20250106-WA0036.jpg",
    "/images/IMG-20250106-WA0037.jpg",
    "/images/IMG-20250106-WA0038.jpg",
    "/images/IMG-20250106-WA0039.jpg",
    "/images/IMG-20250106-WA0040.jpg",
    "/images/IMG-20250106-WA0041.jpg",
    "/images/IMG-20250106-WA0042.jpg",
  ];

  const images7 = [
    "/images/IMG-20250106-WA0043.jpg",
    "/images/IMG-20250106-WA0044.jpg",
    "/images/IMG-20250106-WA0045.jpg",
    "/images/IMG-20250106-WA0046.jpg",
    "/images/IMG-20250106-WA0047.jpg",
    "/images/IMG-20250106-WA0048.jpg",
    "/images/IMG-20250106-WA0049.jpg",
  ];

  const images8 = [
    "/images/IMG-20250106-WA0050.jpg",
    "/images/IMG-20250106-WA0051.jpg",
    "/images/IMG-20250106-WA0052.jpg",
    "/images/IMG-20250106-WA0053.jpg",
    "/images/IMG-20250106-WA0054.jpg",
    "/images/IMG-20250106-WA0055.jpg",
    "/images/IMG-20250106-WA0056.jpg",
  ];

  const images9 = [
    "/images/IMG-20250106-WA0057.jpg",
    "/images/IMG-20250106-WA0058.jpg",
    "/images/IMG-20250106-WA0059.jpg",
    "/images/IMG-20250106-WA0060.jpg",
    "/images/IMG-20250106-WA0061.jpg",
    "/images/IMG-20250106-WA0062.jpg",
    "/images/IMG-20250106-WA0063.jpg",
  ];

  const images10 = [
    "/images/IMG-20250106-WA0064.jpg",
    "/images/IMG-20250106-WA0065.jpg",
    "/images/IMG-20250106-WA0066.jpg",
    "/images/IMG-20250106-WA0067.jpg",
    "/images/IMG-20250106-WA0068.jpg",
    "/images/IMG-20250106-WA0069.jpg",
    "/images/IMG-20250106-WA0070.jpg",
  ];
  const images11 = [
    "/images/IMG-20250106-WA0071.jpg",
    "/images/IMG-20250106-WA0072.jpg",
    "/images/IMG-20250106-WA0073.jpg",
    "/images/IMG-20250106-WA0074.jpg",
    "/images/IMG-20250106-WA0075.jpg",
    "/images/IMG-20250106-WA0076.jpg",
    "/images/IMG-20250106-WA0077.jpg",
  ];

  const images12 = [
    "/images/IMG-20250106-WA0078.jpg",
    "/images/IMG-20250106-WA0079.jpg",
    "/images/IMG-20250106-WA0080.jpg",
    "/images/IMG-20250106-WA0081.jpg",
    "/images/IMG-20250106-WA0082.jpg",
    "/images/IMG-20250106-WA0083.jpg",
    "/images/IMG-20250106-WA0084.jpg",
  ];

  return (
    <div>
      <h1>Photos</h1>
      <p>We are passionate about serving your needs to make your occasions Bright and Beauty</p>

      <div className="image-gallery">
      {images1.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>
      <div className="image-gallery">
      {images2.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
     
      </div>

      <div className="image-gallery">
      {images3.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>

      <div className="image-gallery">
      {images4.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>


      <div className="image-gallery">
      {images5.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>


      <div className="image-gallery">
      {images6.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>


      <div className="image-gallery">
      {images7.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>

      <div className="image-gallery">
      {images8.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>


      <div className="image-gallery">
      {images9.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}


</div>
        <div className="image-gallery">
        {images10.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>

      <div className="image-gallery">
        {images11.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>
      <div className="image-gallery">
        {images12.map((image, index) => (
        <div className="image-item">
           <img src={image} alt="Flower 1" onClick={() => openPopup(image)}/>
          </div>
      ))}
      {selectedImage && (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={selectedImage} alt="Full size" />
      </div>
    </div>
  )}
      </div>
 




    </div>


    
  );
}

export default About;
